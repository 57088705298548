<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import axios from "axios";
    import Swal from "sweetalert2";
    import $ from "jquery"

    export default {
        page: {
            title: "Konfigurasi Disclaimer",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return{
                title: "Konfigurasi Disclaimer",
				items: [
					{
						text: "Master",
						href: "/",
					},
					{
						text: "Konfigurasi Disclaimer",
						active: true,
					},
				],
                mps_id: this.$route.params.mps_id,
                data_perizinan: [],
                loadingTable: true,
                list_disclaimer: [],
                data_disclaimer: [],
                deleted_data: [],
            }
        },
        mounted(){
            let self = this;
            // load data disclaimer per perizinan sub
            var config_data_disclaimer = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/get-disclaimer",
                params:{
                    mps_id: self.mps_id
                },
                headers:{
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                }
            }
            axios(config_data_disclaimer).then(function (response){
                var response_data = response.data;
                var response_data_fix = response_data.data.konfig;
                self.data_perizinan = response_data.data.perizinan;
                if(response_data.meta.code == 200){
                    let clear_data = [];
					$.each(response_data_fix, function (indexInArray, valueOfElement) { 
						clear_data.push({
							'id': valueOfElement.mdk_id,
							'disclaimer_konfig': valueOfElement.disclaimer,
							'urutan': valueOfElement.mdk_urutan
						})
					});
                    self.loadingTable = false;
                    self.data_disclaimer = clear_data;
                    console.log(self.data_disclaimer);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response_data_fix.data.message
                    });
                }
            }).catch(function (error){
                console.log(error)
            });

            // load data list disclaimer
            var config_ref_dokumen_persyaratan = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-disclaimer",
                headers:{
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                }
            }
            axios(config_ref_dokumen_persyaratan).then(function (response){
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if(response_data.meta.code == 200){
                    self.list_disclaimer = response_data_fix.referensi;
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response_data_fix.data.message
                    });
                }
            }).catch(function (error){
                console.log(error)
            });
        },
        methods:{
            addRowDokumen(){
                let self = this;
                self.data_disclaimer.push({
                    'id':'',
                    'disclaimer_konfig':'',
                    'urutan':''
                });
            },
            deleteRow(index, row_data){
                let self = this;
                var idx = self.data_disclaimer.indexOf(row_data);
                if(row_data?.id){
                    self.deleted_data.push(row_data?.id);
                }

                if(idx > -1){
                    self.data_disclaimer.splice(idx,1);
                }

                console.log(self.deleted_data);
            },
            StoreData(){
                let self = this;
                Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});

                var config_store_data = {
                    method: "post",
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/disclaimer",
                    data:{
                        mps_id: self.mps_id,
                        disclaimer_data: JSON.stringify(self.data_disclaimer),
                        deleted_data: JSON.stringify(self.deleted_data)
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };

                axios(config_store_data).then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan di muat ulang.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-sub-perizinan" });
                        }
                    });
                }).catch(function (error) {
                    console.log(error);
                });
            }
        }
    }
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card-title">Semua Data Konfigurasi Disclaimer Sub Perizinan</div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end"></div>
                                </div>
                                <div class="col-md-12">
                                    <div class="alert alert-info" role="alert">
										<table class="table table-sm table-borderles">
											<tbody>
												<tr>
													<th style="width:10%;">Jenis Perizinan</th>
													<th> : </th>
													<td>
														<span v-if="data_perizinan?.perizinan?.perizinanjenis?.mpj_nama">{{data_perizinan.perizinan.perizinanjenis.mpj_nama}}</span>
													</td>
												</tr>
												<tr>
													<th>Perizinan</th>
													<th> : </th>
													<td>
														<span v-if="data_perizinan?.perizinan?.mp_nama">{{data_perizinan.perizinan.mp_nama}}</span>
													</td>
												</tr>
												<tr>
													<th>Sub Perizinan</th>
													<th> : </th>
													<td>
														<span v-if="data_perizinan?.mps_nama">{{data_perizinan.mps_nama}}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
                                    <table class="table mb-0 table-bordered table-condensed table-hover">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px;">No</th>
                                                <th>Disclaimer</th>
                                                <th style="width: 150px;">Urutan</th>
                                                <th style="width: 75px;" class="text-center">
                                                    <b-button type="button" class="btn btn-success btn-sm" v-on:click="addRowDokumen">
                                                        <i class="fa fa-plus"></i>
                                                    </b-button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="4"><i class="fas fa-spin fa-refresh"></i> Loading...</td>
                                            </tr>
                                            <tr v-for="(row_data, key_disclaimer) in data_disclaimer" :key="key_disclaimer" v-else>
                                                <td class="text-center">
                                                    {{key_disclaimer+1}}.
                                                </td>
                                                <td>
                                                    <v-select :options="list_disclaimer" label="md_nama" v-model="row_data.disclaimer_konfig" placeholder="Pilih Disclaimer ..."></v-select>
                                                </td>
                                                <td>
                                                    <input type="number" class="form-control" v-model="row_data.urutan">
                                                </td>
                                                <td>
                                                    <div class="text-center">
                                                        <div class="btn btn-sm btn-danger" v-on:click="deleteRow(key_disclaimer, row_data)"><i class="fa fa-minus"></i></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="text-end mt-3">
                                        <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>